import { Component, Input, ViewEncapsulation, NgModule } from '@angular/core';

@Component({
    selector: 'app-main-page-wrapper-no-border',
    templateUrl: './app-main-page-wrapper-no-border.component.html',
    styleUrls: ['./app-main-page-wrapper-no-border.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true
})
export class AppMainPageWrapperNoBorder {
    @Input() containerClass: string;
}


